import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import BookAppointmentButton from "../../components/BookAppointmentButton";
import Layout from "../../layouts/index";
import Contacts from "../../components/Contacts";
import OpeningHours from "../../components/OpeningHours";
import Map from "../../components/Map";
import { Container, Section } from "../../components/LayoutUtils";
import MailingListModal from "../../components/MailingListModal";
import ButtonModal from "../../components/ButtonModal";
import Button from "../../components/Button";
import { H1 } from "../../components/Type";
import { map, button, buttonContainer } from "./style.module.scss";

const MailingListButton = ({ onClick }) => {
  return (
    <Button secondary onClick={onClick}>
      Join mailing list
    </Button>
  );
};

MailingListButton.propTypes = { onClick: PropTypes.func.isRequired };

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="Contact us"
      description="Come visit us our studio right in the middle of the City of London, a stones-throw away from the Bank of England."
    />
    <Container>
      <Section>
        <H1>Contact us</H1>
      </Section>
      <div className="row">
        <div className="col-md-6 order-md-12">
          <Section>
            <Contacts {...data.contentfulContactInformation} />
            <OpeningHours />
            <div className={buttonContainer}>
              <BookAppointmentButton className={button} />
              <ButtonModal
                Modal={MailingListModal}
                Button={MailingListButton}
              />
            </div>
          </Section>
        </div>
        <div className="col-md-6 order-sm-1 order-md-1">
          <Section>
            <Map
              className={map}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDHG5gQVfOfciOqdiXU0xM4mRRLVPuIGiM&v=3.exp"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Section>
        </div>
      </div>
    </Container>
  </Layout>
);

ContactPage.propTypes = {
  data: PropTypes.shape({
    contentfulContactInformation: PropTypes.shape({
      instagram: PropTypes.string,
      email: PropTypes.string,
      telephoneNumber: PropTypes.string,
      address: PropTypes.string,
      postcode: PropTypes.string
    })
  }).isRequired
};

export default ContactPage;

export const query = graphql`
  query {
    contentfulContactInformation {
      instagram
      email
      telephoneNumber
      address
      postcode
    }
  }
`;
