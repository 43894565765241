import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class ButtonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }

  toggle = () => {
    this.setState(state => ({ modalVisible: !state.modalVisible }));
  };

  close = () => {
    this.setState(() => ({ modalVisible: false }));
  };

  render() {
    const { modalVisible } = this.state;
    const { Modal, Button } = this.props;
    return (
      <Fragment>
        <Button onClick={this.toggle} />
        <Modal modalVisible={modalVisible} onClose={this.close} />
      </Fragment>
    );
  }
}

ButtonModal.propTypes = {
  Modal: PropTypes.func.isRequired,
  Button: PropTypes.func.isRequired
};

export default ButtonModal;
