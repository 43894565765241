import React from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import mapStyles from "./mapStyles";

const MapComponent = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      mapTypeId="roadmap"
      defaultOptions={{ styles: mapStyles }}
      options={{ styles: mapStyles }}
      defaultCenter={{ lat: 51.513035, lng: -0.085652 }}
      defaultZoom={18}
    >
      <Marker position={{ lat: 51.51294, lng: -0.085716 }} />
    </GoogleMap>
  ))
);

const MapWrapper = props => {
  const { className } = props;
  return (
    <div className={className}>
      <MapComponent {...props} />
    </div>
  );
};

MapWrapper.propTypes = {
  className: PropTypes.string.isRequired
};

export default MapWrapper;
