import React from "react";
import PropTypes from "prop-types";
import ContactMethod from "../ContactMethod";
import { H2 } from "../Type";

const Contacts = ({ telephoneNumber, email, instagram, postcode, address }) => (
  <section style={{ marginBottom: "1em" }}>
    <div className="row">
      <div className="col-12">
        <H2>Details</H2>
      </div>
    </div>
    <ContactMethod
      name="Telephone"
      link={<a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>}
    />
    <ContactMethod
      name="Email"
      link={<a href={`mailto:${email}`}>{email}</a>}
    />
    <ContactMethod
      name="Instagram"
      link={<a href={`https://instagram.com/${instagram}`}>@{instagram}</a>}
    />
    <ContactMethod
      name="Address"
      link={`${address}, City of London, ${postcode}`}
    />
  </section>
);

Contacts.propTypes = {
  telephoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired
};

export default Contacts;
