import React from "react";
import PropTypes from "prop-types";

const ContactMethod = ({ name, link }) => (
  <div className="row">
    <div className="col-3">
      <b>{name}</b>
    </div>
    <div className="col-9">{link}</div>
  </div>
);

ContactMethod.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default ContactMethod;
