import React from "react";
import ContactMethod from "../ContactMethod";
import { H2 } from "../Type";

const OpeningHours = () => (
  <section>
    <div className="row">
      <div className="col-12">
        <H2>Opening hours</H2>
      </div>
    </div>
    <ContactMethod name="Monday" link="10am - 4pm" />
    <ContactMethod name="Tuesday" link="10am - 4pm" />
    <ContactMethod name="Wednesday" link="10am - 4pm" />
    <ContactMethod name="Thursday" link="10am - 4pm" />
    <ContactMethod name="Friday" link="10am - 4pm" />
    <ContactMethod name="Sat-Sun" link="By appointment" />
  </section>
);

export default OpeningHours;
